import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { IEndpointConfiguration } from '@/shared/interfaces';
import { IpcObserverService } from '@/shared/services/ipc/ipc-observer.service';
import { MediaDevicesService } from '@/shared/services/media-devices';
import { MetricService } from '@/shared/services/metrics';
import { SessionService } from '@/shared/services/session';
import { VideoService } from '@/shared/services/video';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

@Component({
    selector: 'tvkit-incoming-page-component',
    templateUrl: './tvkit-incoming-page.component.html',
})
export class TVKitIncomingPageComponent implements OnInit, OnDestroy {
    public audio: HTMLAudioElement = new Audio('');
    public defaultAudioOutput: any;
    public defaultAudioInput: any;
    public config: IEndpointConfiguration;

    private _videoRoom: any;
    private _convergeCall = false;

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(DOCUMENT) public _document: Document,
        private _mediaDevicesService: MediaDevicesService,
        private _ipcObserver: IpcObserverService,
        private _router: Router,
        private _metricService: MetricService,
        private _sessionService: SessionService,
        private _videoService: VideoService
    ) {}

    ngOnInit() {
        this._videoRoom = this._videoService.getRoom();
        if (!!this._videoRoom?.conferenceVendorId) {
            this._convergeCall = true;
        }
        this._document.body.style.cursor = 'none';

        this.config = this._sessionService.getConfig();
        this.defaultAudioOutput =
            this._mediaDevicesService.defaultAudioOutput();
        this.defaultAudioInput = this._mediaDevicesService.defaultAudioInput();
        const autoAnswerRingtoneDuration = window.location.href.includes(
            'local'
        )
            ? 7000
            : 7000;

        /** Setting ringtone volume */
        this.changeAudioGain(this.config.RingtoneDefaultVolume);

        const tvReadySubscription = this._ipcObserver
            .addSubscription(SYSTEM_COMMANDS_TYPES.TV_READY)
            .pipe(
                timeout(autoAnswerRingtoneDuration),
                catchError(() => of('finished'))
            )
            .subscribe(() => {
                this._metricService.sendMetrics({
                    call_state: '2',
                });
                /** setting Speaker gain */
                this.changeAudioGain(this.config.AudioOutputDefaultVolume);

                // determine which route to navigate to
                const route = this._convergeCall
                    ? ['/intmhomescreen/converge-video']
                    : ['/tvkit-video/intm-video'];
                this._router.navigate(route);
            });

        this.subscriptions.push(tvReadySubscription);

        this.audio = this.getAudioElement();
        if (this.defaultAudioOutput) {
            (this.audio as any).setSinkId(this.defaultAudioOutput);
        }
        if (this.audio) {
            this.audio.play();
        }
    }
    // add to avoid error during ng test
    // logic isn't changed
    // NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
    getAudioElement(): HTMLAudioElement {
        return new Audio('assets/audio/incoming.m4a');
    }

    changeAudioGain(defaultVolume) {
        if (this.defaultAudioOutput) {
            const msg = {
                volume: defaultVolume,
                vendorId: this.defaultAudioOutput.vendorId,
                productId: this.defaultAudioOutput.productId,
                name: this.defaultAudioOutput.name,
            };
            this._sessionService.deviceInstance.setSpeakerGain(msg);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((item) => item.unsubscribe());

        this._document.body.style.cursor = 'default';

        if (this.audio) {
            this.audio.pause();
        }
    }
}
