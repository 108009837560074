<div
    class="wrapper-caller-info white-text flex flex-column items-center justify-center"
    style="background-image: url('../assets/images/incoming-bg.webp')"
>
    <div class="caller-white-block transparent">
        <caller></caller>
        <section>
            <h3 class="m-b2 m-t4 center white-text">Your call will begin shortly</h3>
        </section>
    </div>
</div>
